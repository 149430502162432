import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  TextField,
  Grid,  
  FormControl,
  InputLabel
} from '@mui/material';

//import Contrato from './Contrato';

const Parcelas = ({ agregarAlPresupuesto }) => {
  
  //const [itemsSeleccionados, setItemsSeleccionados] = useState([]); // Para eliminar los ítems seleccionados
  const [planSeleccionado, setPlanSeleccionado] = useState(null);
  const [codigo, setCodigo] = useState({
    codigo: '',        
  });

  const planes = [
    { id: 1, item: 'Plan de Contado', modalidad: 'De Contado', servicio: 'Plan de Contado - Fosa/Parcela', tipo_servicio: 'Fosas o Parcelas', precio: 1500 },
    { id: 2, item: 'Plan-01', modalidad: 'Financiado', servicio: 'Plan-01 - Fosa/Parcela', tipo_servicio: 'Fosas o Parcelas', precio: 1700 },
    { id: 3, item: 'Plan-02', modalidad: 'Financiado', servicio: 'Plan-02 - Fosa/Parcela', tipo_servicio: 'Fosas o Parcelas', precio: 2000 },
  ];

  const handleCodigoChange = (e) => {
    setCodigo((prev) => ({
      ...prev,
      codigo: e.target.value
    }));
  };  

  const handlePlanChange = (e) => {
    const planId = parseInt(e.target.value, 10);
    const plan = planes.find((p) => p.id === planId);
    setPlanSeleccionado(plan);
  };

  const handleAgregar = () => {
    if (planSeleccionado) {
      const item = {
        codigo: codigo.codigo,       
        tipo_servicio: 'Fosas o Parcelas',        
        descripcion: planSeleccionado.servicio,
        modalidadPago: planSeleccionado.modalidad === 'Financiado' ? 'Financiado' : 'De Contado',
        precioTotal: planSeleccionado.precio,
        pagoInicial: '',
        montoPorCuota: ''
        
      };

      agregarAlPresupuesto(item);           
    }
  };

  return (
    <>

      <Typography
        sx={{ marginBottom: '5px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575', textAlign: 'center' }}
        variant="h5"
      >
        CONTRATO FOSAS O PARCELAS
      </Typography>
      <Box>
        <Typography sx={{ marginTop: '15px', marginBottom: '10px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} variant="h6">
          PLAN A CONTRATAR
        </Typography>
      </Box>

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth size="small" variant="outlined" sx={{ boxShadow: 3 }}>
            <InputLabel id="planParcela-label">Plan</InputLabel>
            <Select
              labelId="planParcela-label"  // Se asegura que el InputLabel esté conectado
              id="planParcela"
              label="Plan"
              value={planSeleccionado ? planSeleccionado.id : ''}
              onChange={handlePlanChange}
              autoComplete="off"
            >
              <MenuItem value="" disabled>
                Selecciona un Plan
              </MenuItem>
              {planes.map((plan) => (
                <MenuItem key={plan.id} value={plan.id}>
                  {plan.servicio} - {plan.item} - ${plan.precio || plan.precio}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Monto a Pagar"
            variant="outlined"
            size="small"
            value={planSeleccionado ? `$${planSeleccionado.precio}` : ''}
            InputProps={{ readOnly: true }}
            sx={{ boxShadow: 3 }}
          />
        </Grid> 
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Codigo Parcela"
            variant="outlined"
            size="small"
            value={codigo.codigo}
            onChange={handleCodigoChange}           
            sx={{ boxShadow: 3 }}
          />
        </Grid>         
        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAgregar}
            disabled={!planSeleccionado} // Deshabilitar si no hay plan seleccionado
          >
            Agregar al Contrato
          </Button>
        </Grid>
      </Grid>        
    </>
  );
};

export default Parcelas;
