// TablasContratos.js
import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

export const TablasContratos = ({ tipoServicio, detalles }) => {  
  const renderTable = () => {  
      if (!detalles || detalles.length === 0) {  
          return <p>No hay detalles disponibles.</p>;  
      }  

    switch (tipoServicio) {  // Asegúrate que coincidan mayúsculas  
        case 'Previsivo':  
            return (  
                <TableContainer>  
                    <Table>  
                        <TableHead>  
                            <TableRow sx={{ backgroundColor: '#0A4575' }}>  
                                <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Tipo de Contrato</TableCell>  
                                <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Descripción</TableCell>  
                                <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Precio Plan</TableCell>  
                                <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Modalidad de Pago</TableCell>  
                                <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Precio Total</TableCell>  
                            </TableRow>  
                        </TableHead>  
                        <TableBody>  
                            {detalles.map(detalle => (  
                                <TableRow key={detalle.id}>  
                                    <TableCell>{detalle.tipo_contrato}</TableCell>  
                                    <TableCell>{detalle.descripcion}</TableCell>  
                                    <TableCell>{`$${detalle.precio || 0}`}</TableCell>  
                                    <TableCell>{detalle.modalidadPago}</TableCell>  
                                    <TableCell>{`$${detalle.precioTotal || 0}`}</TableCell>  
                                </TableRow>  
                            ))}  
                        </TableBody>  
                    </Table>  
                </TableContainer>  
            );  

        case 'Servicio Funerario':  
        return (  
            <TableContainer>  
                <Table>  
                    <TableHead>  
                        <TableRow sx={{ backgroundColor: '#0A4575' }}>  
                            <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Tipo de Contrato</TableCell>  
                            <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Descripción</TableCell>  
                            <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>traslado</TableCell>  
                            <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Tiempo de Velación</TableCell> 
                            <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Precio Velación</TableCell>
                            <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Preparación del Difunto</TableCell>
                            <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Alquiler Ataud</TableCell>                            
                            <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Precio Total</TableCell>  
                        </TableRow>  
                    </TableHead>  
                    <TableBody>  
                        {detalles.map(detalle => (  
                            <TableRow key={detalle.id}>  
                                <TableCell>{detalle.tipo_contrato}</TableCell>  
                                <TableCell>{detalle.descripcion}</TableCell>  
                                <TableCell>{`$${detalle.traslado || 0}`}</TableCell> 
                                <TableCell>{detalle.tiempo_velacion}</TableCell> 
                                <TableCell>{`$${detalle.precio_velacion || 0}`}</TableCell>
                                <TableCell>{`$${detalle.incluye_preparacion || 0}`}</TableCell>
                                <TableCell>{`$${detalle.incluye_ataud || 0}`}</TableCell>                                 
                                <TableCell>{`$${detalle.precioTotal || 0}`}</TableCell>  
                            </TableRow>  
                        ))}  
                    </TableBody>  
                </Table>  
            </TableContainer>  
              ); 
        case 'Fosas o Parcelas':  
            return (  
                <TableContainer>  
                    <Table>  
                        <TableHead>  
                            <TableRow sx={{ backgroundColor: '#0A4575' }}>  
                                <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Tipo de Contrato</TableCell>  
                                <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Descripción</TableCell>
                                <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Modalidad de Pago</TableCell>                             
                                <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Precio Total</TableCell>  
                            </TableRow>  
                        </TableHead>  
                        <TableBody>  
                            {detalles.map(detalle => (  
                                <TableRow key={detalle.id}>  
                                    <TableCell>{detalle.tipo_contrato}</TableCell>  
                                    <TableCell>{detalle.descripcion}</TableCell>  
                                    <TableCell>{detalle.modalidadPago}</TableCell>                                  
                                    <TableCell>{`$${detalle.precioTotal || 0}`}</TableCell>  
                                </TableRow>  
                            ))}  
                        </TableBody>  
                    </Table>  
                </TableContainer>  
            );                              
          default:  
              return <p>No hay detalles disponibles para este tipo de servicio.</p>;  
      }  
  };  

  return <div>{renderTable()}</div>;  
};
